<template>
  <v-row class="ma-5" align="center" justify="center">
    <v-col  class="fixedwidthhomecol">
      <br/>
      <h1>{{$vuetify.lang.t('$vuetify.SQApp_Documentation')}}</h1>
      <br/>
      <v-list dense>
        <template v-for="(item, index) in documentationMenuItems" @click="item.onclick">
          <v-list-item :key="item.title" :href="getDocHref(item)" >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider class="my-1" v-if="index < documentationMenuItems.length - 1" :key="index"></v-divider>
        </template>
      </v-list>
      <br/>
    </v-col>
  </v-row>
</template>
<script>

  export default {
    name: 'Docs',
    data(){
      return {
        documentationMenuItems: [
            {
              title: this.$vuetify.lang.t('$vuetify.Documentation_Intro'), onclick: () => {
                //router.push(RouteDoc2);
              },
              hrefru: 'documents/Intro.pdf?' + this.AntiCacheSuffix(),
              hrefen: 'documents/Intro.pdf?' + this.AntiCacheSuffix(),
              icon: 'mdi-download',
            },
          {
            title: this.$vuetify.lang.t('$vuetify.Documentation_Setup'), onclick: () => {
              //router.push(RouteDoc2);
            },
            hrefru: 'documents/Setup.pdf?' + this.AntiCacheSuffix(),
            hrefen: 'documents/Setup.pdf?' + this.AntiCacheSuffix(),
            icon: 'mdi-download',
          },
          {
            title: this.$vuetify.lang.t('$vuetify.Documentation_LifeCycle'), onclick: () => {
              //router.push(RouteDoc2);
            },
            hrefru: 'documents/LifeCycle.pdf?' + this.AntiCacheSuffix(),
            hrefen: 'documents/LifeCycle.pdf?' + this.AntiCacheSuffix(),
            icon: 'mdi-download',
          },
          ]
      }
    },

    components: {
    },
    methods: {
      getDocHref(docItem) {
        if (this.$vuetify.lang.current === 'ru')
          return docItem.hrefru;
        else
          return docItem.hrefen;
      },
    }
  }
</script>